import React, { useState } from "react";
import "antd/dist/antd.css";
import { Grid } from '@mui/material'
import { Modal, Button } from "antd";
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import PDF from "react-pdf-js";
const PdfViewer = ({ pdf, onCancel, visible }) => {

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const [scale, setScale] = useState(1.2);

    let lastLoad = Date.now()
    const onDocumentComplete = (numPages) => {
        setPages(numPages)
    }

    const onDocumentError = (err) => {
        console.error('pdf viewer error:', err);
    }

    const onSetScale = (type) => {
        if (Date.now() - lastLoad < 500)
            return;

        var newScale = type ? scale + 0.1 : scale - 0.1;
        if (newScale > 2) {
            newScale = 2
        } else if (newScale < 0.1) {
            newScale = 0.1
        }
        setScale(newScale)
    }

    const onPage = (type) => {
        var newPage = type ? page + 1 : page - 1
        if (newPage > pages) {
            newPage = 1
        } else if (newPage < 1) {
            newPage = pages
        }
        setPage(newPage)
    }

    const zoomStyle = {
        marginLeft: 10,
        cursor: 'pointer'
    }

    const footer = <div style={{ display: 'flex', justifyContent: 'space-between', overflowX: 'auto' }}>
        {/* <Button onClick={() => onPage(0)}>Previous</Button> */}
        <div style={{ margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', overflowX: 'auto' }}>
            <span style={{ textAlign: 'center' }}>Page {page} of {pages}</span>
            <Button style={{ ...zoomStyle, opacity: scale === 0.1 ? 0.5 : 1 }} onClick={() => { onSetScale(0) }}> -  </Button>
            <Button style={{ ...zoomStyle, opacity: scale === 2 ? 0.5 : 1 }} onClick={() => { onSetScale(1)}}> + </Button>
            <span>{Math.round(scale * 100)}%</span>
        </div>
        {/* <Button onClick={() => onPage(1)}>Next</Button> */}
    </div>

    return (
    <Grid>
        <Modal
            onCancel={onCancel}
            open={visible}
            bodyStyle={{ height: 650, overflowY: 'auto'}}
            style={{ top: 20, right: 15, bottom: 20, width: { md: "35%", sm: "55%", xs: "85%" } }}
            // width={}
            destroyOnClose
            footer={footer}
            >
            <div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflowX: 'auto' }}>
                <PDF
                    file={pdf}
                    onDocumentComplete={onDocumentComplete}
                    onDocumentError={onDocumentError}
                    page={page}
                    scale={scale}
                />
            </div>
        </Modal>
    </Grid>
)};

export default PdfViewer;