import React, { useState, useEffect } from 'react'
import Product from './Product/Product'
import Results from './Results/Results'
import CompareSection from './CheckoutSection/CheckoutSection'
import { Grid } from '@mui/material';
import { getAllProducts } from '../../api/productApi.js'
import Cart from '../CartWithCount/CartWithCount'
import { USER_CHECKOUT } from '../../consts.js';
import pdf from "../../images/EnergyGuide.pdf"
import PdfViewer from "../ProductDetail/PdfViewer/PdfViewer.js"
import { CURRENT_USER_ID } from '../../consts.js';

export default function Products(props) {
    const boxVersion = JSON.parse(sessionStorage.getItem(CURRENT_USER_ID))?.version;
    const [currentPdf, setCurrentPdf] = useState(pdf)
    const [showPdf, setShowPdf] = useState(false);
    const [products, setProducts] = React.useState([]);
    const [drawerState, setDrawerState] = React.useState(false);
    const [checkoutProducts, setcheckoutProducts] = React.useState(
        JSON.parse(sessionStorage.getItem(USER_CHECKOUT)) || []);
    
    const [currentProductHighlighted, setCurrentProductHighlighted] = React.useState('');
    
    let timer;

    React.useEffect(() => {
        sessionStorage.setItem(USER_CHECKOUT, JSON.stringify(checkoutProducts))
    }, [ checkoutProducts ])

    React.useEffect(()=>{
        getAllProducts()
        .then(res => {setProducts(res)})
        props.activityTrigger({ activity: 'page-navigate' })
    }, [])

    function toggleDrawer(open){
        setDrawerState(open);
    };

    function addProductToCompare(newProd){
        if(checkoutProducts.length <= 4 && !checkoutProducts.includes(newProd)){
            props.activityTrigger({ activity: 'cart-add', id: newProd })
            setcheckoutProducts(prevVal => ([...prevVal, newProd]))
        }
    }

    function removeProductFromCompare(oldProd){
        props.activityTrigger({ activity: 'cart-remove', id: oldProd })
        setcheckoutProducts(prevVal => (prevVal.filter((currentProd) => currentProd!==oldProd)))
    }

    function loadPdf(product) {
        let idx = 0
        if (boxVersion >= 1 && boxVersion <= 3) {
            idx = 0
        } else if(boxVersion === 4) {
            idx = 1
        } else {
            idx = 2
        }

        const newPdf = product.energyGuides[idx] ? (`${process.env.REACT_APP_API_URL}/image/${product.energyGuides[idx].fileName}`) : pdf
        setCurrentPdf(newPdf)
    }

    function prepareProducts(products) {
        return (products.map(product => <Grid item 
                                              xs={12} sm={6} md={4} xl={4}
                                              key={product._id}
                                              style={{ paddingBottom: '15px', minWidth: '50px' }}>
                                            <Product key={product._id} 
                                                    data={product}
                                                    inCart={checkoutProducts.includes(product._id)}
                                                    addToCompare={addProductToCompare}
                                                    removeFromCompare={removeProductFromCompare}
                                                    toggleDrawer={toggleDrawer} 
                                                    triggerPdf={() => { loadPdf(product); setCurrentProductHighlighted(product._id); setShowPdf(true) }}
                                                    activityTrigger={props.activityTrigger} />
                                        </Grid>))
    }
    
    function startTimer() {
        timer = Date.now()
    }

    if (showPdf)
        startTimer()

    function stopTimer() {
        const timeHovered = Date.now() - timer
        props.activityTrigger({ activity: 'pdf-view', timespent: timeHovered, id: currentProductHighlighted })
    }

    function closePdf() {
        stopTimer()
        setShowPdf(false)
    }

    return (
        <>
            <PdfViewer pdf={currentPdf}
                        onCancel={() => closePdf()}
                        onClose={() => closePdf()}
                        visible={showPdf}
                />
            <Grid container spacing={2} sx={{ paddingTop: '25px', paddingLeft: { xs: '25px', sm: '75px', md: '100px', xl: '200px' }, paddingRight: { xs: '25px', sm: '75px', md: '100px', xl: '200px' } }} >
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={8} md={10} xl={11}>
                            <Results results={products.length} />
                        </Grid>
                        <Grid item xs={4} md={2} xl={1}>
                            <Cart toggleDrawer={toggleDrawer} activityTrigger={props.activityTrigger} items={checkoutProducts.length} />
                        </Grid>
                        <CompareSection products={checkoutProducts}
                            removeProducts={removeProductFromCompare}
                            drawer={drawerState}
                            toggleDrawer={toggleDrawer} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {prepareProducts(products)}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}