import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Checkout from '../components/Checkout/Checkout'

export default function ComparePage() {

    return (
        <>
            <Header/>
            <Checkout />
            <Footer/>
        </>
    );
}