import React from 'react'

export default function InvalidPage() {

    return (
        <div id="error-page">
            <h1>Invalid Link</h1>
            <p>Please fill the survey to get a valid URL</p>
        </div>
    );
}