import * as React from 'react';
import { styled } from '@mui/material/styles';
import { CardMedia, Paper, Stack, Grid, Typography } from '@mui/material'
import dummy_image from '../../../images/waterHeater.png'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getProduct } from '../../../api/productApi.js';

export default function Compare(props) {
    const [product, setProduct] = React.useState({}); // TODO: Change for sure!
    React.useEffect(() => {
        getProduct(props.productId)
            .then(res => { setProduct(res) })
    }, [])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1.5),
        color: theme.palette.text.secondary,
    }));

    function showImage(){
        const imageLink = product.images && product.images[0] ? (`${process.env.REACT_APP_API_URL}/image/${product.images[0].fileName}`) : dummy_image;
        if (product && product.images && product.images.length > 0)
            return <CardMedia
                component="img"
                alt="waterHeater"
                image={imageLink}
                sx={{ width: 'auto', maxHeight: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
            />
        return <CardMedia
            component="img"
            alt="waterHeater"
            image={dummy_image}
            sx={{ width: 'auto', maxHeight: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        />
    }

    function removeMe(){
        props.removeProducts(props.productId);
    }

    return (
        <Grid key={props.value} item sx={{ alignItems: 'center', justifyContent:"center" }}>
            <Item>
                <Stack direction="column" 
                    alignItems="center" 
                    spacing={2}>
                    {showImage()}
                    <Typography variant='caption'>{product && product.title && (product.title.substring(0,20) + "...")}</Typography>
                    <RemoveCircleIcon onClick={removeMe} sx={{marginLeft: "25px"}} />
                </Stack>
            </Item>
        </Grid>
    );
}

