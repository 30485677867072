import React from 'react';
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import dummy_image from '../../../images/waterHeater.png'
import { useNavigate } from "react-router-dom";
import { USER_RESP_ID } from '../../../consts';
import  BlueBox from '../../ProductDetail/BlueBox/BlueBox'
// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
// import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

export default function Product(props) {
    const respId = sessionStorage.getItem(USER_RESP_ID)
    const { _id, title, price, description, images, heatPump, costImage, costFormula, thermometerScaled, energyPrice, costImageSecond } = props.data
    const imageLink = images[0] ? (`${process.env.REACT_APP_API_URL}/image/${images[0].fileName}`) : dummy_image;
    let starTimer;
    let navigate = useNavigate();

    const routeChange = () => {
        if(props.silent)
            return
        props.activityTrigger({ activity: 'product-view', id: _id })
        navigate(`product/${_id}?respId=${respId}`);
    }

    function startTimer(){
        starTimer = Date.now()
    }

    function stopTimer(){
        const timeHovered = Date.now() - starTimer
        if (timeHovered > 1000)
            props.activityTrigger({ activity: 'product-hover', timespent: timeHovered, id: _id})
    }

    return (
        <Card sx={{ maxWidth: 750 }} onMouseEnter={() => startTimer()} onMouseLeave={() => stopTimer()}>
            <CardActionArea onClick={routeChange}>
                <CardContent sx={{ minHeight: 130 }}>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                </CardContent>
                <CardMedia
                    component="img"
                    alt="waterHeater"
                    image={imageLink}
                    style={{width: 'auto', maxHeight: '300px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                />
                <CardContent>
                    <Typography variant="title" sx={{ color: 'black', fontWeight: 'bold', fontSize: { xs: '1.75rem', sm: '2rem' } }}>
                        {"$" + price}
                    </Typography>
                    {/* <Typography variant="caption" color="text.secondary">
                        {description.substring(0,60) + "..."}
                    </Typography> */}
                    <BlueBox trigger={props.triggerPdf} rebate={heatPump} costImage={costImage} costFormula={costFormula} thermometerScaled={thermometerScaled} energyPrice={energyPrice} costImage4={costImageSecond} />
                </CardContent>
            </CardActionArea>
            {/* <CardActions>
                <Button size="small" 
                        startIcon={props.inCart ? <RemoveShoppingCartIcon/> : <AddShoppingCartIcon />} 
                        onClick={props.inCart ? removeProductFromCompare : addProductToCompare} 
                        sx={{ color: 'primary', ':hover': { bgcolor: 'blue', color: 'white' } }}>
                        {props.inCart ? 'Remove from Cart' : 'Add to Cart'}
                </Button>
            </CardActions> */}
        </Card>
    );
}
