import React from 'react'
import { styled } from '@mui/material/styles';
import { Stack, Grid, Paper, Box, ListItem, ListItemText, Typography } from '@mui/material'
import Specification from './Specification/Specification';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2.5),
    color: theme.palette.text.secondary,
}));

export default function PostDetail(props) {
    function populateSomething(){
        if (!props.paraBullets)
            return
        return props.paraBullets.map((bullet, idx) => 
            <ListItem key={idx} sx={{ padding: 0, display: "list-item"}}>
                {/* <ListItemText sx={{ color: 'secondary' }} primary={bullet} /> */}
                <Typography sx={{ fontSize: '0.9rem' }}>{bullet} </Typography>
            </ListItem>)
        // return (props.paraBullets.map((bullet, idx) => <li key={idx}>{bullet}</li> ))
    }
    return (
        <Box>
            <Stack sx={{ marginRight: '30px', marginLeft: '30px' }} spacing={2}>
                <Stack item xs={5} spacing={0.5}>
                    <Item>
                        <Typography sx={{ fontSize: '0.9rem' }}> {props.description} </Typography>
                        <ul>
                            {populateSomething()}
                        </ul>
                    </Item>
                </Stack>
                {/* <Grid item xs={5}>
                    <Item>
                        
                    </Item>
                </Grid> */}
                <Specification features={props.features}/>
            </Stack>
        </Box>
    )
}