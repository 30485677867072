import * as React from 'react';
import {Box, Drawer, Button, Grid} from '@mui/material'
import Compare from '../Checkout/Checkout'
import { useNavigate } from 'react-router-dom'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { Typography } from 'antd';
import { Stack } from '@mui/system';

export default function CompareSection(props) {
  const productCards = props.products.length > 0 ? props.products : []
  const navigate = useNavigate();
  function showFromCart(){
    if (productCards.length > 0)
      return (productCards.map((productId, index) => (
        <Compare key={index} productId={productId} removeProducts={props.removeProducts} />
      )))
    else {
      return (<Stack alignItems="center"
                     direction="column"
                     sx={{paddingTop: '50px', paddingLeft: '50px'}}>
                <ProductionQuantityLimitsIcon sx={{ fontSize: 100 }} />
                <Typography variant="body1"> Your shopping cart is empty </Typography>
            </Stack>)
    }
  }
  const list = (
    <Box
      sx={{ width: 'auto', m: 5}}
      role="presentation"
      onKeyDown={() => props.toggleDrawer(false)}
    >
        <Grid container justifyContent="center" xs={12}>
            <Grid container justifyContent="center" spacing={8}>
              {showFromCart()}
            </Grid>
            <Button sx={{ color: 'primary', ':hover': { bgcolor: 'blue', color: 'white' } }} onClick={() => navigate(`/checkout`)} disabled={productCards.length == 0}>
              Checkout these products
            </Button>
            <Button sx={{ color: 'primary', ':hover': { bgcolor: 'blue', color: 'white' } }} onClick={() => props.toggleDrawer(false)}>
              Continue Shopping
            </Button>
        </Grid>
    </Box>
  );

  return (
    <div>
        <React.Fragment key={'bottom'}>
          <Drawer
              anchor={'bottom'}
              open={props.drawer}
              onClose={() => props.toggleDrawer(false)}>
              {list}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
