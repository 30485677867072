import React from 'react'
import { Grid, Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Cart from '../../CartWithCount/CartWithCount'
import { USER_RESP_ID } from "../../../consts"

export default function Detail(props) {
    const respId = sessionStorage.getItem(USER_RESP_ID)
    const navigate = useNavigate();
    return (
        <Grid container sx={{marginTop: '10px', marginLeft: '15px'}} spacing={1.5}>
            <Grid item xs={8} md={2.5} lg={1.5}>
                <Link
                    component="button"
                    variant="body3"
                    onClick={() => { navigate(`/?respId=${respId}`) }}
                >
                    <Typography variant="p" sx={{ color: 'black' }}>{"Home / " + props.category}</Typography>
                </Link>
            </Grid>
            <Grid item xs={4} md={9.5} lg={10.5} />
            <Grid item xs={8} md={10} lg={8}>
                <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>{props.name}</Typography>
            </Grid>
            <Grid item xs={0} md={2} lg={3}/>
            <Grid item xs={1} md={1} lg={1}>
                <Cart toggleDrawer={(open) => props.toggleDrawer(open)} items={props.checkoutLength} activityTrigger={props.activityTrigger}/>
            </Grid>
            <Grid item xs={12} md={10} lg={6}>
                <Typography variant="caption" sx={{ color: 'gray' }}>{"Model Number: "+ props.modelNumber}</Typography>
            </Grid>
        </Grid>
    )
}