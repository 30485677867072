import React from 'react'
import { Container, Button, Typography, Tooltip, Grid } from '@mui/material'
import { Box, Stack } from '@mui/system';
import CheckoutCard from './CheckoutCard/CheckoutCard';
import PublishIcon from '@mui/icons-material/Publish';
import { submitWaterHeater } from '../../api/userApi.js'
import { useNavigate } from "react-router-dom";
import { USER_RESP_ID, USER_CHECKOUT } from '../../consts.js';

export default function Checkout(props){
    const respId = sessionStorage.getItem(USER_RESP_ID)
    const [checkoutArray, setCheckoutArray] = React.useState(
        JSON.parse(sessionStorage.getItem(USER_CHECKOUT)) || [])
    const [canRemove, setCanRemove] = React.useState(true)

    React.useEffect(() => {
        sessionStorage.setItem(USER_CHECKOUT, JSON.stringify(checkoutArray))
        calculateRemove()
    }, [checkoutArray])

    React.useEffect(()=> {
        calculateRemove()
    },[])

    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate(`${path}?respId=${respId}`);
    }

    function calculateRemove(){
        setCanRemove(checkoutArray.length !== 1);
    }

    function removeFromCheckout(oldProd) {
        if (checkoutArray.includes(oldProd)) {
            setCheckoutArray(prevVal => (prevVal.filter((currentProd) => currentProd !== oldProd)))
        }
    }

    function prepareCheckoutElements(){
        return (checkoutArray.map(product => <CheckoutCard key={product} 
                                                           productId={product} 
                                                           removeFromCheckout={removeFromCheckout} 
                                                           canRemove={canRemove}/>))
    }

    function submitReport(){
        submitWaterHeater(respId, checkoutArray[0]) // TODO: Use JWT
        routeChange('/')
    }

    return (
        <Container maxWidth="sm" sx={{marginTop: "100px", padding: '20px'}}>
            <Box>
                <Stack spacing={2} direction="column">
                    <Grid container spacing={3}>
                        {prepareCheckoutElements()}
                    </Grid>
                    <Tooltip title="Submit report">
                        <Button disabled={canRemove}
                                variant="contained"
                                sx={{ color: 'black', ':hover': { bgcolor: 'blue', color: 'white' } }}
                                onClick={()=> submitReport()}
                                startIcon={<PublishIcon />} >
                            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}> {!canRemove ? "Select water heater and return to survey" : "You may only checkout one water heater.\nPlease remove items from your cart."} </Typography>
                        </Button>
                    </Tooltip>
                </Stack>
            </Box>
        </Container>
    )
}