import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from '@mui/material';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

const useStyles = makeStyles({
    root: {
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center"
    },
    icon: {
        fontSize: "2.5em",
        transform: 'scale(1.5)'
    },
    count: {
        position: "absolute",
        borderRadius: '100%',
        backgroundColor: "#1976d2",
        color: "white",
        minHeight: '1.5em',
        minWidth: '1.5em',
        top: "-0.5em",
        right: "1.5em", 
        fontSize: '0.75em !important'
    }
});

export default function Cart(props){
    const classes = useStyles();
    function openCart(){
        props.activityTrigger({ activity: 'cart-open' })
        props.toggleDrawer(true)
    }
    return (
        <div className={classes.root} style={{ fontSize: 10 }}>
            <Button startIcon={<ShoppingCartCheckoutIcon />}
                    className={classes.icon}
                    sx={{ color: 'primary' }}
                    onClick={() => openCart()}>
                <Typography className={classes.count}> {props.items || 0} </Typography>
            </Button>
        </div>)
}