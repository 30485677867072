import React from 'react';
import { useNavigate } from 'react-router-dom'
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Link, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { USER_RESP_ID } from '../../consts';

const pages = ['Home'];
const appName = 'Appliance Store';


const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    let navigate = useNavigate();
    const respId = sessionStorage.getItem(USER_RESP_ID)

    const routeChange = (path) => {
        navigate(`${path}?respId=${respId}`);
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    return (
        <AppBar position="static" style={{ background: '#000000' }}>
            <Container maxWidth="xl" style={{ background: '#000000' }}>
                <Toolbar disableGutters style={{ background: '#000000' }}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                        <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'block' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => routeChange(`/`)}>
                                    <Typography textAlign="center" sx={{ fontFamily: 'monospace'}}>{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <StoreMallDirectoryIcon sx={{ display: { xs: 'flex', md: 'block' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        onClick={() => routeChange(`/`)}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            fontSize: {xs: 17, sm: 20}
                        }}
                    >
                        {appName}
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
