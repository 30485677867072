import React from 'react'
import { Box, Button, CardMedia, Grid } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import dummy_image from '../../../images/waterHeater.png'

export default function MainImage(props) {
    function showImage(){
        if (props.currentImage)
            return <CardMedia
                component="img"
                sx={{ height: { xs: 250, md: 500 }, width: {xs: 250, md: 500}, margin: 'auto' }}
                image={`${process.env.REACT_APP_API_URL}/image/${props.currentImage.fileName}`}
                alt="Live from space album cover"
            />
        else
            return <CardMedia
                component="img"
                sx={{ height: 500, width: 475 }}
                image={dummy_image}
                alt="Live from space album cover"
            />
    }
    return (
        <Box sx={{ paddingLeft: "20px", justifyContent: "center", alignItems: "center", display:"flex" }}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={1} md={1}>
                    <Button startIcon={<ArrowBackIosIcon />} onClick={() => props.clickImagesBack()}  />
                </Grid>
                <Grid item xs={8.5} md={8.6} sx={{ alignItems: 'center' }}>
                    {showImage()}
                </Grid>
                <Grid item xs={1} md={1}>
                    <Button startIcon={<ArrowForwardIosIcon />} onClick={() => props.clickImagesFoward()}/>
                </Grid>
            </Grid>
        </Box>
    )
}
