import React from 'react'
import Redirect from "../components/Redirect/Redirect"
import { CURRENT_USER_ID } from "../consts"

export default function CompletedPage() {
    const user = JSON.parse(sessionStorage.getItem(CURRENT_USER_ID))
    const qualtricsUrl = `${process.env.REACT_APP_QUATRICS_BASE_URL}/${user['surveyId']}`
    return (
        <center style={{paddingTop: '150px'}}>
            <Redirect redirectLink={qualtricsUrl} />
        </center>
    );
}