import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getProduct } from '../../../api/productApi.js'
import dummy_image from '../../../images/waterHeater.png'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2.5),
    color: theme.palette.text.secondary,
}));

export default function CheckoutCard(props) {
    const productId = props.productId;
    const [product, setProduct] = React.useState({}); // TODO: Change for sure!
    const imageLink = product.images && product.images[0] ? (`${process.env.REACT_APP_API_URL}/image/${product.images[0].fileName}`) : dummy_image;

    React.useEffect(() => {
        getProduct(productId)
            .then(res => { setProduct(res) })
    }, [productId])

    return (
        <Item sx={{margin: '7px'}}>
            <Grid container spacing={0.5}>
                <Grid item xs={3}>
                    <CardMedia
                        component="img"
                        style={{ width: 'auto', maxHeight: '100px', display: 'block' }}
                        image={imageLink}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Stack direction='column' 
                        spacing={2} 
                        sx={{ paddingTop: '10px' }}>
                        <Typography component="div" variant={{ xs: 'caption', sm: 'h6' }} sx={{ fontWeight: 'bold' }}>
                            {product.title}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" component="div">
                            {product.modelNumber}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={3} sx={{ alignSelf: 'flex-end' }}>
                    <Stack direction='column'
                        spacing={2}
                        alignItems='center'
                        alignContent='center'
                        sx={{paddingTop: '50px'}}>
                            <Typography sx={{ typography: 'caption'}} >
                               Price: $ {product.price}
                            </Typography>
                            <Tooltip title="Remove Product">
                                <Button sx={{ color: 'primary', ':hover': { bgcolor: 'blue', color: 'white' } }}
                                        onClick={() => props.removeFromCheckout(productId)}
                                        disabled={!props.canRemove}>
                                Remove
                                </Button>
                            </Tooltip>
                    </Stack>
                </Grid>
            </Grid>
        </Item >
    );
}
