import React from 'react'
import { Stack, Card, CardMedia, CardActionArea, Divider } from '@mui/material'
import dummy_image from '../../../images/waterHeater.png'

export default function MiniImages(props) {
    function prepareImages(images){
        if(images)
            return (images.map((image, index) =>
                <Card key={index}
                    elevation={0} 
                    onClick={() => props.setImage(index)}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            sx={{ margin: 'auto', maxHeight: 200, maxWidth: 200, opacity: props.currentImage && (image.fileName === props.currentImage.fileName) ? 0.6 : 1 }}
                            image={`${process.env.REACT_APP_API_URL}/image/${image.fileName}`}
                            alt="Live from space album cover"
                        />
                    </CardActionArea>
                </Card>
            ))
        return (<Card>
                    <CardMedia
                        component="img"
                        sx={{ maxHeight: 200, maxWidth: 200}}
                        image={dummy_image}
                        alt="Live from space album cover"
                    />
                </Card>)
    }
    return (
        <Stack spacing={1}
                direction={props.direction} 
                divider={<Divider orientation="horizontal" flexItem />}
                justifyContent="center">
            {prepareImages(props.images)}
        </Stack>
    )
}