import React from "react";
import loadingGif from "../../images/loading-gif.gif"

export default function Redirect(props) {
    const [timer, setTimer] = React.useState(5);

    const redirectToSurvey = () => {
        window.location.href = props.redirectLink
    }
    React.useEffect(() => {
        setInterval(() => {
            setTimer((time) => time - 1)
        }, 1000)
    }, [])

    React.useEffect(() => {
        if (timer === 0) 
            redirectToSurvey()
    }, [timer])


    return (
        <center>
            <h1>You will be redirected back to the survey in {timer} seconds</h1>
            <img style={{ height: '50px', width: '50px' }} src={loadingGif} alt="loading..." />
            <p>If you are not redirected, please click on <a href={`${props.redirectLink}`}>this link</a></p>
        </center>
    );
}
