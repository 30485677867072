import React from 'react'

export default function ErrorPage() {

    return (
        <div id="error-page">
            <h1>Oops 404 NotFound!</h1>
            <p>Sorry, please go back.</p>
        </div>
    );
}