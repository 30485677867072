import { Box, Grid, CardMedia, CardActionArea, Typography, CardContent } from '@mui/material';
import energyGuide from "../../../images/EnergyGuide.png"
import costVisual from "../../../images/EG_Cost_Visual.png"
import thermometer from "../../../images/Thermometer_scaled.png"
import c3 from "../../../images/C3.png"
import React from 'react'
import { CURRENT_USER_ID } from '../../../consts';

export default function BlueBox(props){
    const boxVersion = JSON.parse(sessionStorage.getItem(CURRENT_USER_ID))?.version;
    const costFormula = props.costFormula ? (`${process.env.REACT_APP_API_URL}/image/${props.costFormula.fileName}`) : c3;
    const costImage = props.costImage ? (`${process.env.REACT_APP_API_URL}/image/${props.costImage.fileName}`) : costVisual;
    const costImage4 = props.costImage4 ? (`${process.env.REACT_APP_API_URL}/image/${props.costImage4.fileName}`) : costVisual;
    const thermometerScaled = props.thermometerScaled ? (`${process.env.REACT_APP_API_URL}/image/${props.thermometerScaled.fileName}`) : thermometer;

    function boxColor() {
        switch (boxVersion) {
            case 1: return ['primary.dark', 'primary.main']
            case 2: return ['secondary.dark', 'secondary.main']
            case 3: return ['warning.dark', 'warning.main']
            case 4: return ['info.dark', 'info.main']
            case 5: return ['info.dark', 'info.main']
            case undefined: return ['primary.dark', 'primary.main']
        }
    }

    function showSolidBox() {
        return <Box
            sx={{
                width: { xs: 212, sm: 130, lg: 240, xl: 345 },
                height: { xs: 54, sm: 32, lg: 60, xl: 70 },
                maxWidth: { xs: 212, sm: 150, md: 350 },
                maxHeight: { xs: 54, sm: 40, md: 80 },
                backgroundColor: boxColor()[0],
                '&:hover': {
                    backgroundColor: boxColor()[1],
                    opacity: [0.9, 0.8, 0.7],
                },
            }}
        />
    }

    function whichVersion(){
        if(boxVersion === 1){
            return (
                <Grid container spacing={1}>
                    <Grid item xs={2.5} md={2}>
                        <CardActionArea onMouseDown={event => event.stopPropagation()}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                            }}>
                            <CardMedia
                                component="img"
                                image={energyGuide}
                                alt="Energy Guide"
                                onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                </Grid>
            )
        }else if(boxVersion === 2){
            return (
                <Grid container spacing={0.5} alignItems="flex-end">
                    <Grid item xs={2.5} md={2}>
                        <CardActionArea onMouseDown={event => event.stopPropagation()}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                            }}>
                            <CardMedia
                                component="img"
                                image={energyGuide}
                                alt="Energy Guide"
                                onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={6.5} md={8}>
                        <Typography variant="caption" sx={{ color: 'black', fontWeight: 'bold', fontSize: { sm: '0.9rem' } }}> Estimated yearly cost: ${props.energyPrice} </Typography>
                    </Grid>
                </Grid>
            )
        } else if (boxVersion === 3) {
            return (
                <Grid container spacing={0.75} alignItems="center">
                    <Grid item xs={2.5} md={2}>
                        <CardActionArea onMouseDown={event => event.stopPropagation()}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                            }}>
                            <CardMedia
                                component="img"
                                image={energyGuide}
                                alt="Energy Guide"
                                onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={5} md={4}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                image={costImage}
                                alt="Energy Guide"
                                // onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                </Grid>
            )
        } else if (boxVersion === 5) {
            return (
                <Grid container spacing={0.75}>
                    <Grid item xs={2.5} lg={2}>
                        <CardActionArea onMouseDown={event => event.stopPropagation()}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                            }}>
                            <CardMedia
                                component="img"
                                image={energyGuide}
                                alt="Energy Guide"
                                onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={4.75} lg={3.6}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                image={costImage4}
                                alt="Energy Guide"
                                // onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={4.75} lg={3.7}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                image={thermometerScaled}
                                alt="Energy Guide"
                                // onClick={props.trigger}
                            />
                        </CardActionArea>
                        {/* <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.75rem' } }}>Includes instant rebate</Typography> */}
                    </Grid>
                </Grid>
            )
        } else if (boxVersion === 4) {
            return (
                <Grid container spacing={0.5} alignItems="flex-end">
                    <Grid item xs={2.5} sm={2}>
                        <CardActionArea onMouseDown={event => event.stopPropagation()}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                            }}>
                            <CardMedia
                                component="img"
                                image={energyGuide}
                                alt="Energy Guide"
                                onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={7.5}>
                        <CardActionArea sx={{  }}>
                            <CardMedia
                                component="img"
                                image={costFormula}
                                alt="Energy Guide"
                                // onClick={props.trigger}
                            />
                        </CardActionArea>
                    </Grid>
                </Grid>
            )
        } else {
            return showSolidBox()
        }
    }

    return (<Grid container spacing={1}>
                <Grid item xs={12}>
                    {whichVersion()}
                </Grid>
                <Grid container spacing={0.5} sx={{ paddingTop: 0.75, paddingLeft: 1, minHeight: 60 }}>
                {/* {props.rebate && boxVersion !== 4 && <Grid container xs={12} alignItems="center">
                                                        <Grid item xs={7.5} lg={5.75} sx={{paddingLeft: 0.5}}> 
                                                            <Typography variant="caption" sx={{ color: '#4861b0', fontWeight: 'bold', fontSize: { xs: '0.75rem' } }}> Instant rebate: ${props.energyPrice} </Typography>
                                                        </Grid> 
                                                        {boxVersion == 5 && <Grid item> 
                                                            <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.62rem' } }}>Includes instant rebate</Typography> 
                                                        </Grid>} 
                                                    </Grid>} */}
                    {/* <Grid container xs={12} sx={{ paddingLeft: 8 }} spacing={1}> */}
                    <Grid item xs={7.5} lg={5.75} xl={5.6} sx={{ paddingLeft: 0.5 }}>
                        <Typography variant="subtitle1" sx={{ color: '#484848', fontSize: { xs: '0.75rem' } }}> Expected lifespan ≥ 10 years</Typography>
                    </Grid>
                    {props.rebate && boxVersion !== 4 && <Grid item>
                        <Typography variant="subtitle1" sx={{ color: '#4861b0', fontWeight: 'bold', fontSize: { xs: '0.75rem' }}}>After ${props.energyPrice} instant rebate</Typography>
                    </Grid>}
                    {/* </Grid> */}
                </Grid>
            </Grid>)
}
