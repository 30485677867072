import axios from 'axios';
import { USER_RESP_ID } from '../consts'

export async function getAllProducts() {
    const respId = sessionStorage.getItem(USER_RESP_ID)
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products-active`, { params: { respId: respId }});
        const { products } = response.data;
        return products;
    } catch (error) {
        return [];
    }
}

export async function getProduct(productId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${productId}`);
        const { products } = response.data;
        return products;
    } catch (error) {
        return {};
    }
}

export async function getMiniProducts() {
    const respId = sessionStorage.getItem(USER_RESP_ID)
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products-minified`, { params: { respId: respId } });
        const { products } = response.data;
        return products;
    } catch (error) {
        return [];
    }
}