import React from 'react'
import { isMobile } from "react-device-detect";
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Products from '../components/Products/Products'
import { updateActivity } from '../api/userApi.js'
import { USER_RESP_ID } from '../consts.js';

export default class Home extends React.Component {
    constructor(props){
        super(props)
        this.state = {activity: [{activity: 'timestamp', datetime: Date.now(), device: isMobile ? "Mobile" : "Desktop"}]}
        this.startTimer = Date.now()
        this.addActivity = (newActivity) => {
            let activityArr = this.state.activity
            activityArr.push({ ...newActivity, currentPage: 'home', datetime: Date.now()})
            this.setState({ activity: activityArr })
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return nextState.activity === this.state.activity;
    }

    componentWillUnmount(){
        this.addActivity({ timespent: Date.now() - this.startTimer, activity: 'home', device: isMobile ? "Mobile" : "Desktop"})
        updateActivity(sessionStorage.getItem(USER_RESP_ID), this.state.activity); // Not sure if status check is required ?!
    }

    render(){
        return (<>
            <Header />
            <Products activityTrigger={(newActivity) => this.addActivity(newActivity)} />
            <Footer />
        </>)
    }
}