import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { USER_RESP_ID } from '../../consts';

function Copyright() {
    const respId = sessionStorage.getItem(USER_RESP_ID)
    return (
        <Typography variant="body2" color="white">
            {'Copyright © '}
            <Link color="inherit" href={`https://ed-r.herokuapp.com/?respId=${respId}`}>
                Appliance Store
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Footer() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '30vh',
            }}
        >
            <CssBaseline />
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Container maxWidth="sm">
                    {/* <Typography variant="body1" sx={{ color: 'white' }}>
                        This is the footer
                    </Typography> */}
                    <Copyright />
                </Container>
            </Box>
        </Box>
    );
}