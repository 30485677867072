import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, List, Button, Stack, Grid, Paper, Typography } from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import BlueBox from '../BlueBox/BlueBox';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
}));

export default function ProductDets(props) {
    const product = props.product;
    const incart = props.checkoutProducts.includes(props.id);

    function switchProduct(){
        incart ? props.removeProductFromCompare(props.id) : props.addProductToCompare(props.id);
        props.toggleDrawer(true)
    }

    function showBullets(bullets){
        return (bullets && bullets.map(text => <li> {text} </li>))
    }

    return (
        <Box>
            <Stack sx={{ marginRight: '30px' }} spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="h6" sx={{ paddingLeft: {xl: '45px'}, color: 'black', fontWeight: 'bold' }}>{"Price : $" + product.price}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <BlueBox extraPadding={true} product={product} trigger={props.trigger} rebate={props.triggerRebate} costFormula={product.costFormula} costImage={product.costImage} thermometerScaled={product.thermometerScaled} energyPrice={product.energyPrice} costImage4={product.costImageSecond} />
                </Grid>
                <Grid item>
                    <Item>
                        <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
                            <ol style={{ listStyleType: "disc"}}>
                                {showBullets(product.bullets)}
                            </ol>
                        </List>
                    </Item>
                </Grid>
                <Button sx={{ color: 'primary', ':hover': { bgcolor: 'blue', color: 'white' } }}
                        startIcon={incart ? <RemoveShoppingCartIcon /> : <AddShoppingCartIcon />} 
                        onClick={() => {switchProduct()}}>
                    {incart ? <Typography>Remove from Cart</Typography> : <Typography>Add to Cart</Typography>}
                </Button>
            </Stack>
        </Box>
    )
}