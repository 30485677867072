import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';


export default function Specification(props) {
    function createData(key, value) {
        return { key, value};
    }

    const rows = props.features ? props.features.map((feature) => createData(feature.key, feature.value)) : [
        createData('Frozen yoghurt', 159),
        createData('Ice cream sandwich', 237),
        createData('Eclair', 262),
        createData('Cupcake', 305),
        createData('Gingerbread', 356)];

    return (
        <Grid container spacing={2} 
            justifyContent="center"
            alignItems="center">
            <Grid item xs={1}>
                <Typography>
                    SPECIFICATION
                </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid container spacing={2}>
                <Grid item lg={2} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block'}}}></Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TableContainer component={Paper} sx={{ maxWidth: '375px' }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {rows.slice(0,rows.length/2 + 1).map((row) => (
                                    <TableRow
                                        key={row.key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {row.key}
                                        </TableCell>
                                        <TableCell align="right">{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TableContainer component={Paper} sx={{ maxWidth: '375px' }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {rows.slice(rows.length / 2 + 1).map((row) => (
                                    <TableRow
                                        key={row.key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.key}
                                        </TableCell>
                                        <TableCell align="right">{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    )
}