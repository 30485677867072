import React from 'react'
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { updateActivity } from '../api/userApi.js'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ProductDetail from '../components/ProductDetail/ProductDetail'
import { USER_RESP_ID } from '../consts.js';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class Product extends React.Component {
    constructor(props) {
        super(props)
        this.state = { activity: [] }
        this.productId = this.props.params ? this.props.params.productId : undefined
        this.startTimer = Date.now()
        this.addActivity = (newActivity) => {
            let activityArr = this.state.activity
            activityArr.push({ ...newActivity, currentPage: 'product', datetime: Date.now() })
            this.setState({ activity: activityArr })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.activity === this.state.activity;
    }

    componentWillUnmount() {
        this.addActivity({ timespent: Date.now() - this.startTimer, activity: 'product', id: this.productId, device: isMobile ? "Mobile" : "Desktop" })
        updateActivity(sessionStorage.getItem(USER_RESP_ID), this.state.activity); // Not sure if status check is required ?!
    }

    render() {
        return (<>
            <Header />
            <ProductDetail activityTrigger={(newActivity) => this.addActivity(newActivity)} />
            <Footer />
        </>)
    }
}

export default withParams(Product);