import axios from 'axios';
import { CURRENT_USER_ID } from '../consts'

export async function authUser(respId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${respId}`);
        const { user, auth, isValid } = response.data;
        return { user: user, auth: auth, isValid: isValid};
    } catch (error) {
        return {};
    }
}

export async function submitWaterHeater(respId, heaterNo) {
    try {
        const payload = { respId: respId, jwt: null, heaterNo: heaterNo };
        await axios.post(`${process.env.REACT_APP_API_URL}/users/complete`, payload);
        refreshUser(respId)
    } catch (error) {
        return {};
    }
}

export async function refreshUser(respId){
    const res = await authUser(respId) // TODO: Change endpoint
    sessionStorage.setItem(CURRENT_USER_ID, JSON.stringify(res.user))
    window.location.reload();
}

export async function updateActivity(respId, activity){
    const payload = { respId: respId, activity: activity}
    const resp = await axios.post(`${process.env.REACT_APP_API_URL}/users/activity`, payload);
    return resp.status === 200
}