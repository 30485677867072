import React, { useState, useEffect } from 'react';
import { Stack, Grid } from '@mui/material'
import { useParams } from "react-router-dom";
import Detail from './Detail/Detail';
import MainImage from './MainImage/MainImage';
import MiniImages from './MiniImages/MiniImages';
import ProductDets from './ProductDets/ProductDets';
import PostDetail from './PostDetail/PostDetail'
import { getProduct } from '../../api/productApi.js'
import PdfViewer from "./PdfViewer/PdfViewer"
import pdf from "../../images/EnergyGuide.pdf"
import CompareSection from '../Products/CheckoutSection/CheckoutSection'
import { USER_CHECKOUT, CURRENT_USER_ID } from '../../consts.js';
// import { PropaneSharp } from '@mui/icons-material';
import('./styles.css')

export default function ProductDetail(props) {
    const boxVersion = JSON.parse(sessionStorage.getItem(CURRENT_USER_ID))?.version
    const [pdfToShow, setPdfToShow] = useState(pdf)

    const { productId } = useParams();
    const [product, setProduct] = useState({}); // TODO: Change for sure!
    const [showPdf, setShowPdf] = useState(false);
    const [currentImage, setCurrentImage] = useState(0); 
    const [drawerState, setDrawerState] = React.useState(false);
    const [checkoutProducts, setcheckoutProducts] = React.useState(
        JSON.parse(sessionStorage.getItem(USER_CHECKOUT)) || []);
    let base = false, timer;

    useEffect(() => {
        window.addEventListener('scroll', onScroll, false)
        return () => window.removeEventListener('scroll', onScroll, false)
    }, [])


    function loadPdf(product) {
        if(product == {}) return

        let idx = 0
        if (boxVersion >= 1 && boxVersion <= 3) {
            idx = 0
        } else {
            idx = 1
        }

        const newPdf = product.energyGuides[idx] ? (`${process.env.REACT_APP_API_URL}/image/${product.energyGuides[idx].fileName}`) : pdf
        setPdfToShow(newPdf)
    }

    const onScroll = (e) => {
        let totalHeight
        totalHeight = window.scrollY + window.innerHeight
        if (totalHeight >= 1400) {
            if (base == true) return
            props.activityTrigger({ activity: 'past-specification', id: productId })
            base = true
        }
    }

    if(showPdf)
        startTimer()

    function closePdf(){
        stopTimer()
        setShowPdf(false)
    }

    function startTimer() {
        timer = Date.now()
    }

    function stopTimer() {
        const timeHovered = Date.now() - timer
        props.activityTrigger({ activity: 'pdf-view', timespent: timeHovered, id: productId })
    }

    React.useEffect(() => {
        sessionStorage.setItem(USER_CHECKOUT, JSON.stringify(checkoutProducts))
    }, [checkoutProducts])

    React.useEffect(() => {
        getProduct(productId)
            .then(res => { setProduct(res); loadPdf(res) })
        props.activityTrigger({ activity: 'page-navigate', id: productId })
    }, [productId])

    function toggleDrawer(open) {
        setDrawerState(open);
    };

    function addProductToCompare(newProd) {
        if (checkoutProducts.length <= 4 && !checkoutProducts.includes(newProd)){
            props.activityTrigger({ activity: 'cart-add', id: newProd })
            setcheckoutProducts(prevVal => ([...prevVal, newProd]))
        }
    }

    function removeProductFromCompare(oldProd) {
        if (checkoutProducts.includes(oldProd)) {
            props.activityTrigger({ activity: 'cart-remove', id: oldProd })
            setcheckoutProducts(prevVal => (prevVal.filter((currentProd) => currentProd !== oldProd)))
        }
    }

    function clickImagesFoward() {
        if (product && product.images){
            setCurrentImage(prevIndex => ((prevIndex + 1) % product.images.length))
            props.activityTrigger({ activity: 'image-click-next', id: productId, imageNumber: currentImage })
        }
    }

    function clickImagesBack() {
        if (product && product.images){
            setCurrentImage(prevIndex => ((prevIndex + product.images.length - 1) % product.images.length))
            props.activityTrigger({ activity: 'image-click-back', id: productId, imageNumber: currentImage })
        }
    }

    function clickImage(imageId){
        props.activityTrigger({ activity: 'image-click-precise', id: productId, imageNumber: imageId })
        setCurrentImage(imageId);
    }

    return (
        <>
            <PdfViewer pdf={pdfToShow}
                       onCancel={() => closePdf()}
                       onClose={() => closePdf()}
                       visible={showPdf}
            />
            <Stack spacing={1} sx={{ paddingLeft: { xs: '10px', sm: '50px', xl: '75px' }, paddingRight: { xs: '10px', sm: '50px', xl: '75px' } }}>
                <Detail name={product.title} 
                        modelNumber={product.modelNumber} 
                        category={product.category}
                        checkoutLength={checkoutProducts.length} 
                        toggleDrawer={toggleDrawer}
                        activityTrigger={props.activityTrigger}/>
                <Grid container spacing={2}>
                    <Grid item xs={2} sm={2} md={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <MiniImages direction="column" 
                                    images={product && product.images} 
                                    currentImage={product.images && product.images[currentImage]}
                                    setImage={(index) => clickImage(index)}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{alignContent: 'center', alignItems: 'center'}}>
                        <MainImage clickImagesFoward={()=>clickImagesFoward()} 
                                   clickImagesBack={() => clickImagesBack()} 
                                   currentImage={product.images && product.images[currentImage]} />
                    </Grid>
                    <Grid item xs={2} sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <MiniImages direction="row"
                            images={product && product.images}
                            currentImage={product.images && product.images[currentImage]}
                            setImage={(index) => clickImage(index)} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <ProductDets id={productId} 
                                     product={product}
                                     checkoutProducts={checkoutProducts}
                                     trigger={() => {setShowPdf(true);}}
                                     removeProductFromCompare={removeProductFromCompare}
                                     addProductToCompare={addProductToCompare}
                                     toggleDrawer={toggleDrawer}
                                     triggerRebate={product.heatPump}
                                      />
                    </Grid>
                </Grid>
                <PostDetail paraBullets={product.paraBullets} description={product.description} features={product.features}/>
            </Stack>
            <CompareSection products={checkoutProducts}
                removeProducts={removeProductFromCompare}
                drawer={drawerState}
                toggleDrawer={toggleDrawer} />
        </>
    )
}
